import axios from 'axios';


export function productsList(id, page, PerPage) {
    console.log('productsList')
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "StoreId": id,
        "Page": page,
        "PerPage": PerPage
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductsRequest`,
        postData, config,
    );
}

export function getProduct(id) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "ProductId": id
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductRequest`,
        postData, config,
    );
}

export function shoppersList(page, PerPage) {
    console.log('productsList')
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "Page": page,
        "PageSize": PerPage,
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetStoreByFiltersCommand`,
        postData, config,
    );
}

// export function ordersList(page, PerPage) {
//     console.log('productsList')
//     let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
//     const token = 'Bearer ' + dumbToken.data
//     const config = {
//         headers:
//         {
//             'Authorization': token,
//             'Content-Type': 'application/json'
//         }
//     }

//     const postData = {
//         "Page": page,
//         "PerPage": PerPage
//       }

//     return axios.post(
//         `https://core.pskeromais.com.br/api/v1/GetOrdersByFilterRequest`,
//         postData, config,
//     );
// }

export function productsShop( id , page, perPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "StoreId": id,
        "Page": page,
        "PerPage": perPage
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductsRequest`,
        postData, config,
    );
}

export function productPrice( id, CostPrice , SalePrice , OnSalePrice ) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "ProductId": id,
        "CostPrice": CostPrice,
        "SalePrice": SalePrice,
        "OnSalePrice": OnSalePrice
      }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/UpdatePriceProductCommand`,
        postData, config,
    );
}

export function productAddStock( id, qtd) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "ProductId": id,
        "Quantity": qtd
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/AddStockProductCommand`,
        postData, config,
    );
}

export function productRmvStock( id, qtd) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "ProductId": id,
        "Quantity": qtd
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/SubtractStockCommand`,
        postData, config,
    );
}
