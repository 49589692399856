import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Modal, Row, Col, Card, Table, Nav, Tab } from 'react-bootstrap';
import { getProduct } from '../../services/ProductService';
//import tabDataProducts from './subtabs/tabDataProducts.json'

const tabData = []

function Product() {
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState('');
    const [productId, setProductId] = useState(null);
    const [toggle, setToggle] = useState(false);
    const [Data, setData] = useState({
        Name: '',
        Weight: 0,
        SalePrice: 0,
        CostPrice: 0,
        Height: 0,
        Width: 0,
        Length: 0,
        Store: null,
        Description: ''
    });
    const [isLargeScreen, ] = useState(window.innerWidth > 700);

    //pega o valor get de p na url

    useEffect(() => {
        if (productId === null) {
            const checkProduct = async () => {
                if (product === '') {
                    try {
                        const params = new URLSearchParams(window.location.search);
                        const pValue = params.get('p');
                        if (pValue) {
                            setProductId(pValue)
                            setToggle(true)
                        } else {

                        }
                    } catch (error) {

                    }
                }
            };
            checkProduct();
        }
    }, [productId]);

    //envia o ID para a consulta na api

    useEffect(() => {
        if (toggle) {
            const fetchProducts = async () => {
                try {
                    const response = await getProduct(productId);
                    if (response.status === 200) {
                        console.log(response.data.result)
                        setProduct(response.data.result);
                        setToggle(false)
                        setLoading(false)
                    } else {
                        setToggle(false)
                        setLoading(false)
                    }
                } catch (error) {
                    setToggle(false)
                    setLoading(false)
                }
            };

            fetchProducts();
        }
    }, [productId, product]);

    return (
        <>
            <div className="custom-tab-1 pb-3">
                <Tab.Container>
                    <Nav as="ul" className="navigatore justify-content-center">
                        {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
            </div>
            <div className="row">
                <div className="col-xl-12 pt-3">
                    {
                        product !== '' ?
                            <div id="customers"
                                className="row"
                                style={{
                                    width: "100%",
                                    padding: 0,
                                    justifyContent: 'center'
                                }}
                            >
                                <div className="col-md-6"
                                    style={{
                                        marginBottom: 6,
                                        borderRadius: 6,
                                    }}>
                                    <div className="p-3">
                                        <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                            {
                                                product.images.length > 0?
                                                isLargeScreen?
                                                    <img className="" style={{ width: '100%' , margin: '0 auto'}} src={'https://core.pskeromais.com.br/' + product.images[0].url}/>
                                                :   <img className="" style={{ width: '100%'  , maxWidth: 300 , margin: '0 auto'}} src={'https://core.pskeromais.com.br/' + product.images[0].url}/>
                                                    : 
                                                    <span style={{
                                                        width: '100%',
                                                        height: 180,
                                                        backgroundImage: "gray",
                                                        margin: '0 auto',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                                    }}>
                                                        <i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
                                                    </span>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6"
                                    style={{
                                        marginBottom: 6,
                                        borderRadius: 6,
                                    }}>
                                    <div
                                        className="p-3">
                                        <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                            <div className="media d-flex justify-content-center">
                                                <div>
                                                    <br />
                                                    <h3 className="mb-0 fs--1">
                                                        {product.name}
                                                    </h3>
                                                </div>
                                                {/* <div className="media d-flex mt-3" style={{ gap: 4 }}>
                                                    <button className='btn btn-secondary' style={{ padding: 0, border: 0, width: 33, height: 33, borderRadius: '100%' }}>
                                                        <i className="bi bi-pencil-square"></i>
                                                    </button>
                                                    <button className='btn btn-primary' style={{ padding: 0, border: 0, width: 33, height: 33, borderRadius: '100%' }}>
                                                        <i className="bi bi-x-circle"></i>
                                                    </button>
                                                </div> */}
                                            </div>
                                            <hr />
                                            <p style={{ textIndent: '24px', textAlign: 'justify' }}>
                                                {product.description}
                                            </p>
                                            <div style={{ display: 'flex', gap: 6, flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                                                <span>
                                                    <label style={{ fontSize: 10 }}>
                                                        peso
                                                    </label>
                                                    <span style={{ backgroundColor: 'rgb(241, 241, 241)', padding: 3, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
                                                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{product.weight + ' g'}</span>
                                                    </span>
                                                </span>
                                                <span>
                                                    <label style={{ fontSize: 10 }}>custo</label>
                                                    <span style={{ backgroundColor: 'rgb(241, 241, 241)', padding: 3, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
                                                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{product.costPrice.toFixed(2) + ' R$'}</span>
                                                    </span>
                                                </span>
                                                <span>
                                                    <label style={{ fontSize: 10 }}>venda</label>
                                                    <span style={{ backgroundColor: 'rgb(241, 241, 241)', padding: 3, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
                                                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{product.salePrice.toFixed(2) + ' R$'}</span>
                                                    </span>
                                                </span>
                                                <span>
                                                    <label style={{ fontSize: 10 }}>vitrine</label>
                                                    <span style={{ backgroundColor: 'rgb(241, 241, 241)', padding: 3, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
                                                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{product.onSalePrice.toFixed(2) + ' R$'}</span>
                                                    </span>
                                                </span>
                                                <span>
                                                    <label style={{ fontSize: 10 }}>estoque</label>
                                                    <span style={{ padding: 3, borderRadius: '3px', backgroundColor: 'rgb(241, 241, 241)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{product.stock}</span>
                                                    </span>
                                                </span>
                                            </div>
                                            <span>
                                            <span style={{color: 'rgb(245, 71, 73)'}}>
                                                <i className="bi bi-clipboard" ></i> tipo
                                                </span>
                                                <br /> 
                                                <span style={{fontSize: '15px' , fontWeight: 'bold'}}>
                                                { 
                                                    product.categoryId === "54bd475f-7438-49fb-9967-ef0d2ba32c46"?
                                                    'Salgadinho de Milho'
                                                    :product.categoryId === "219cbfd3-dc40-4547-ba16-b68ee3cbb758"?
                                                    'Salgadinho de Trigo'
                                                    :product.categoryId === "d34e4917-4cdb-442e-90e8-e68adc781807"?
                                                    'Pipoca'
                                                    :null
                                                }
                                                </span>  
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            :
                            ' produto não encontrado '
                    }
                </div>
            </div>
            <span style={{ zIndex: 9999, width: '100%', height: '100vh', backgroundColor: 'rgba(41 ,180,226,0.6)', position: 'absolute', top: 0, right: 0, display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }} >
                <i id="loading-arrow-in-circle" class="bi bi-arrow-clockwise" style={{ color: 'white', fontSize: 75 }}></i>
            </span>
        </>
    )
}
export default Product;